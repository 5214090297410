import UsersListings from "views/crm/Ed-Dashboard/UsersListings.jsx";
import AboutChancellorsListings from "views/crm/Ed-Dashboard/AboutChancellorsListings.jsx";
import AboutTeachersListings from "views/crm/Ed-Dashboard/AboutTeachersListings.jsx";
import AwardsAndRecognitionsListings from "views/crm/Ed-Dashboard/AwardsAndRecognitionsListings.jsx";
import FeaturedListings from "views/crm/Ed-Dashboard/FeaturedListings.jsx";
import TestimonialsListings from "views/crm/Ed-Dashboard/TestimonialsListings.jsx";
import AddEditAboutChancellorsListings from "views/crm/Ed-Dashboard/AddEditAboutChancellorsListings.jsx";
import AddEditAboutTeacherListings from "views/crm/Ed-Dashboard/AddEditAboutTeachersListings.jsx";
import AddEditAwardsAndRecognitionsListings from "views/crm/Ed-Dashboard/AddEditAwardsAndRecognitionsListings.jsx";
import AddEditFeaturedListings from "views/crm/Ed-Dashboard/AddEditFeaturedListings.jsx";
import AddEditTestimonialsListings from "views/crm/Ed-Dashboard/AddEditTestimonialsListings.jsx";
import AddBlog from "../views/blog/Blog/AddBlog.jsx";
import Blogs from "../views/blog/Blog/Blogs.jsx";
import EditBlog from "../views/blog/Blog/EditBlog.jsx";
import AddEvent from "../views/blog/Events/AddEvent.jsx";
import EditEvent from "../views/blog/Events/EditEvent.jsx";
import Events from "../views/blog/Events/Events.jsx";
import ContactInfoListings from "../views/crm/Ed-Dashboard/ContactInfoListings.jsx";
import UpdatePackage from "../views/crm/Packages/UpdatePackage.js";
import CounsellerPackageList from "../views/crm/Packages/Counseller.js";
import VendorPackageList from "../views/crm/Packages/Vendor.js";
import CandidatePackageList from "../views/crm/Packages/Candidate.js";
import InstitutePackageList from "../views/crm/Packages/Recruiter.js";
import AddNewPackage from "../views/crm/Packages/addNewPackage.js";
import PaymentList from "../views/crm/Packages/PaymentList.js";

const BASEDIR = process.env.REACT_APP_BASEDIR;

const dashRoutes = [
  {
    path: "#",
    name: "Eduwizer Users",
    icon: "note",
    type: "dropdown",
    parentid: "users",
    child: [
      { path: `${BASEDIR}/crm/users/list`, name: "Users Listing" },
      { path: `${BASEDIR}/crm/users/contactlist`, name: "Contact Info Listings" },
    ],
  },
  {
    path: "#",
    name: "Eduwizer Packages",
    icon: "note",
    type: "dropdown",
    parentid: "package",
    child: [
      { path: `${BASEDIR}/crm/packages/addPackage`, name: "Add Package" },
      { path: `${BASEDIR}/crm/packages/counseller`, name: "Counsellor Package" },
      { path: `${BASEDIR}/crm/packages/vendor`, name: "Vendor Package" },
      { path: `${BASEDIR}/crm/packages/candidate`, name: "Candidate Package" },
      { path: `${BASEDIR}/crm/packages/recruiter`, name: "Recruiter Package" },
    ],
  },
  {
    path: "#",
    name: "Eduwizer Blogs & Events",
    icon: "note",
    type: "dropdown",
    parentid: "blogs",
    child: [
      { path: `${BASEDIR}/crm/blogs/list`, name: "Blogs Listing" },
      { path: `${BASEDIR}/crm/events/list`, name: "Events Listing" },
    ],
  },
  {
    path: "#",
    name: "Payment",
    icon: "note",
    type: "dropdown",
    parentid: "payment",
    child: [
      { path: `${BASEDIR}/crm/packages/payment`, name: "Payment Listing" },  // Corrected the path here
    ],
  },
  {
    path: "#",
    name: "Eduwizer Dashboard",
    icon: "note",
    type: "dropdown",
    parentid: "featured-listings",
    child: [
      { path: `${BASEDIR}/crm/featured-listings/list`, name: "Featured Listing" },
      { path: `${BASEDIR}/crm/about-teachers/list`, name: "About Teachers / Lecturers / Administrators Listing" },
      { path: `${BASEDIR}/crm/about-chancellors/list`, name: "About Chancellors Listing" },
      { path: `${BASEDIR}/crm/awards-and-recognitions/list`, name: "Awards And Recognitions Listing" },
      { path: `${BASEDIR}/crm/testimonials/list`, name: "Testimonials" },
    ],
  },
  // Child Routes
  { path: `${BASEDIR}/crm/packages/addPackage`, component: AddNewPackage },
  { path: `${BASEDIR}/crm/packages/payment`, component: PaymentList },  // Ensured PaymentList is correctly mapped
  { path: `${BASEDIR}/crm/packages/counseller`, component: CounsellerPackageList },
  { path: `${BASEDIR}/crm/packages/vendor`, component: VendorPackageList },
  { path: `${BASEDIR}/crm/packages/candidate`, component: CandidatePackageList },
  { path: `${BASEDIR}/crm/packages/recruiter`, component: InstitutePackageList },
  { path: `${BASEDIR}/crm/packages/edit-package/:id`, component: UpdatePackage },

  { path: `${BASEDIR}/crm/users/list`, component: UsersListings },
  { path: `${BASEDIR}/crm/users/contactlist`, component: ContactInfoListings },

  { path: `${BASEDIR}/crm/blogs/list`, component: Blogs },
  { path: `${BASEDIR}/crm/blogs/add`, component: AddBlog },
  { path: `${BASEDIR}/crm/blogs/edit`, component: EditBlog },

  { path: `${BASEDIR}/crm/events/list`, component: Events },
  { path: `${BASEDIR}/crm/events/add`, component: AddEvent },
  { path: `${BASEDIR}/crm/events/edit`, component: EditEvent },

  { path: `${BASEDIR}/crm/featured-listings/list`, component: FeaturedListings },
  { path: `${BASEDIR}/crm/featured-listings/add`, component: AddEditFeaturedListings },
  { path: `${BASEDIR}/crm/featured-listings/edit/:id`, component: AddEditFeaturedListings },

  { path: `${BASEDIR}/crm/about-teachers/list`, component: AboutTeachersListings },
  { path: `${BASEDIR}/crm/about-teachers/add`, component: AddEditAboutTeacherListings },
  { path: `${BASEDIR}/crm/about-teachers/edit/:id`, component: AddEditAboutTeacherListings },

  { path: `${BASEDIR}/crm/about-chancellors/list`, component: AboutChancellorsListings },
  { path: `${BASEDIR}/crm/about-chancellors/add`, component: AddEditAboutChancellorsListings },
  { path: `${BASEDIR}/crm/about-chancellors/edit/:id`, component: AddEditAboutChancellorsListings },

  { path: `${BASEDIR}/crm/awards-and-recognitions/list`, component: AwardsAndRecognitionsListings },
  { path: `${BASEDIR}/crm/awards-and-recognitions/add`, component: AddEditAwardsAndRecognitionsListings },
  { path: `${BASEDIR}/crm/awards-and-recognitions/edit/:id`, component: AddEditAwardsAndRecognitionsListings },

  { path: `${BASEDIR}/crm/testimonials/list`, component: TestimonialsListings },
  { path: `${BASEDIR}/crm/testimonials/add`, component: AddEditTestimonialsListings },
  { path: `${BASEDIR}/crm/testimonials/edit/:id`, component: AddEditTestimonialsListings },
];

export default dashRoutes;
