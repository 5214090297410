import React, { useState, useEffect } from "react";
import { getPaymentHistory } from "../../../Services/api";

function PaymentList() {
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getPaymentHistory()
      .then((response) => {
        setPaymentHistory(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Payments for counsellors:", error);
        setError("Failed to fetch payment history.");
        setLoading(false);
      });
  }, []);

  const calculateExpirationDate = (createdAt, months) => {
    const createdDate = new Date(createdAt);
    createdDate.setMonth(createdDate.getMonth() + months);
    return createdDate;
  };

  const calculateRemainingDays = (expirationDate) => {
    const today = new Date();
    const timeDifference = expirationDate - today;
    const remainingDays = Math.floor(timeDifference / (1000 * 3600 * 24));
    return remainingDays;
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-danger">{error}</div>;
  }

  return (
    <div className="container" style={{ marginTop: "125px" }}>
      <h2>Package List</h2>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Email</th>
              <th>Phone</th>
              <th>UserType</th>
              <th>Package Price</th>
              <th>Duration</th>
              <th>Payment Date</th>
              <th>Expiration</th>
              <th>Payment Screenshot</th>
            </tr>
          </thead>
          <tbody>
            {paymentHistory.length > 0 ? (
              paymentHistory.map((payment) => {
                const expirationDate = calculateExpirationDate(
                  payment.createdAt,
                  payment.packageId.months
                );
                const remainingDays = calculateRemainingDays(expirationDate);

                return (
                  <tr key={payment._id}>
                    <td>{payment.userId.email}</td>
                    <td>{payment.userId.phone}</td>
                    <td>{payment.packageId.user}</td>
                    <td>{payment.packageId.specialPrize}</td>
                    <td>{payment.packageId.months} months</td>
                    <td>{new Date(payment.createdAt).toLocaleDateString()}</td>
                    <td style={{ color: remainingDays <= 0 ? "red" : "green" }}>
                      {remainingDays > 0
                        ? `${remainingDays} days remaining`
                        : "Expired"}
                    </td>
                    <td>
                      <a
                        href={payment.screenshot}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={payment.screenshot}
                          alt="Screenshot"
                          style={{ width: "100px", height: "auto" }}
                        />
                      </a>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="8" className="text-center">
                  No payment data available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PaymentList;
